import { UncontrolledDropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { useNavigate } from "react-router";

const DownloadDropdown = () => {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <UncontrolledDropdown>
      <div
        className="nk-quick-nav-icon icon-status icon-status-na"
        onClick={() => navigate("/excelexport-requested-list")}
        style={{ cursor: "pointer" }}
      >
        <Icon name="ni ni-download"></Icon>
      </div>
    </UncontrolledDropdown>
  );
};

export default DownloadDropdown;
